/* Order View, Vue Component */
<template>
  <div>
    <order-summary v-if="entity && entity.Id && !loading"
      :title="`Order - ${entity.Reference} - ${entity.MemberId_FullName}`"
      :order="entity">

      <Can I="update" on="Orders">
      <v-btn color="primary" class="me-3" v-if="entity" :to="{ name: 'order-edit', params: { id: entity.Id } }">
        <v-icon size="18" class="me-1">
          {{ icons.mdiPencilOutline }}
        </v-icon>
        <span>Edit</span>
      </v-btn>
      </Can>
      <Can I="update" on="Orders">
      <v-btn color="primary" class="me-3" v-if="entity" :to="{ name: 'order-updateinfo', params: { id: entity.Id } }">
        <v-icon size="18" class="me-1">
          {{ icons.mdiPencilOutline }}
        </v-icon>
        <span>Customs Info</span>
      </v-btn>
      </Can>
      <Can I="create" on="Quotes" v-if="!entity.QuotePrice && entity.StatusId_Name == 'Held'">
        <action-confirm-dialog @confirmed="ConvertToQuote" openButtonText="Convert To Quote" dialogTitle="Convert To Quote">
            <template v-slot:information>
              <p>You are manually converting this order into a quote.</p>
            </template>
            <v-btn color="primary" class="me-3">
              <v-icon size="18" class="me-1">{{ icons.mdiBookEditOutline }}</v-icon>
              <span>Convert To Quote</span>
            </v-btn>
          </action-confirm-dialog>
      </Can>
      <Can I="read" on="BookIn">
      <v-btn color="primary" class="me-3" v-if="entity" :to="{ name: 'order-print', params: { id: entity.Id } }">
          <v-icon size="18" class="me-1">
              {{ icons.mdiPrinter }}
          </v-icon>
          <span>Print Labels</span>
      </v-btn>
      </Can>
      <Can I="cancel" on="Orders" v-if="entity && !['Cancelled', 'Complete', 'Archived'].includes(entity.StatusId_Name)">
      <action-confirm-dialog @confirmed="CancelOrder" openButtonText="Cancel Order" dialogTitle="Cancel Order">
        <template v-slot:information>
          <p>Cancelling an order <strong>will not refund any payments</strong>.</p>
          <p>Please ensure correct payments and refunds are given before cancelling.</p>
        </template>
        <v-btn color="error" class="me-3">
          <v-icon size="18" class="me-1">{{ icons.mdiCloseCircle  }}</v-icon>
          <span>Cancel Order</span>
        </v-btn>
      </action-confirm-dialog>
      </Can>

    </order-summary>
  <v-card v-if="entity && entity.Id && !loading" id="order-view" class="entity-view mt-6">

      <v-tabs v-model="currentTab">
        <v-tab :to="'#Overview'">Overview</v-tab>
        <v-tab :to="'#Billing'"><v-badge color="error" :value="entity.Invoice_UnpaidCount"  :content="entity.Invoice_UnpaidCount">Billing</v-badge></v-tab>
        <v-tab :to="'#Items'"><v-badge color="secondary" :value="entity.Item_Count"  :content="entity.Item_Count">Items</v-badge></v-tab>
        <v-tab :to="'#Issues'"><v-badge color="error" :value="entity.OrderIssue_UnresolvedCount"  :content="entity.OrderIssue_UnresolvedCount">Issues</v-badge></v-tab>
        <v-tab :to="'#History'">History</v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab">
      <v-tab-item value="Overview">
        <order-overview-tab v-if="entity && entity.Id && !loading" :order="entity"></order-overview-tab>
      </v-tab-item>
      <v-tab-item value="Billing">
        <order-billing-tab v-if="entity && entity.Id && !loading" :order="entity"></order-billing-tab>

        <template v-if="entity && (entity.StatusId_Name == 'Quote' || (
          (entity.StatusId_Name == 'Active' || entity.StatusId_Name == 'Held') && entity.QuotePrice && entity.QuotePrice > 0))">
        <Can I="update" on="Quotes">
        <v-container class="my-6 d-flex">
          <v-btn color="error" small class="me-3" :to="{ name: 'order-quote', params: { id: entity.Id } }">Change Quote</v-btn>

          <!-- <action-confirm-dialog @confirmed="AcceptQuote" openButtonText="Accept Quote" dialogTitle="Accept Quote" commentTitle="Customer Conformation" commentPlaceholder="Please reference customer correspondence that accepts this quote.">
            <template v-slot:information>
              <p>You are manually accepting this quote on behalf of the customer. Please ensure you have correspondence from the customer confirming that they accept the quote.</p>
            </template>
            <v-btn color="error" small class="me-3">
              <v-icon size="18" class="me-1">{{ icons.mdiBookEditOutline }}</v-icon>
              <span>Accept Quote</span>
            </v-btn>
          </action-confirm-dialog> -->
        </v-container>
        </Can>
        </template>
        <template v-else-if="entity && entity.StatusId_Name == 'Quote Pending'">
          <Can I="create" on="Quotes">
          <v-container class="my-6">
            <v-btn color="success" small class="me-3" :to="{ name: 'order-quote', params: { id: entity.Id } }">Create Quote</v-btn>
          </v-container>
          </Can>
        </template>

        <invoice-list title="Invoices" :OrderId="entity.Id" :showSearch="false"></invoice-list>

      </v-tab-item>
      <v-tab-item value="Items">
        <item-list v-if="entity" :OrderStatus="entity.StatusId_Name" @update="ReloadData(entity.Id);" :title="false" :OrderId="entity.Id" :showSearch="false"></item-list>

        <v-container class="my-6">
          <Can I="update" on="BookIn">
          <v-btn color="error" small class="me-3" v-if="CanBookAdditionalItems && entity.Item_Count > 0"
            :to="{ name: 'order-bookadditionalitem', params: { id: entity.Id } }">
            <v-icon size="18" class="me-1">{{ icons.mdiBookOpenVariant }}</v-icon>
            <span>Book Additional Items</span>
          </v-btn>
          <v-btn color="error" small class="me-3" v-if="CanBookAdditionalItems && entity.Item_Count == 0"
            :to="{ name: 'order-bookitem', params: { id: entity.Id } }">
            <v-icon size="18" class="me-1">{{ icons.mdiBookOpenVariant }}</v-icon>
            <span>Book Items</span>
          </v-btn>
          </Can>
          <Can I="create" on="BookIn">
          <v-btn color="error" small class="me-3" v-if="!CanBookAdditionalItems"
            @click="CreateAdditionalOrder">
            <v-icon size="18" class="me-1">{{ icons.mdiPlus }}</v-icon>
            <span>Book Additional Items In New Order</span>
          </v-btn>
          </Can>
          </v-container>

      </v-tab-item>
      <v-tab-item value="Issues">

        <v-container class="mt-3 mb-0">
        <action-confirm-dialog @confirmed="CreateOrderIssue" dialogTitle="Create Order Issue">
          <template v-slot:information>
            <p>An order issue will notify others of a problem and prevent the order from being added to a manifest.</p>
          </template>
          <v-btn small color="error" class="me-3">
            <v-icon size="18" class="me-1">{{ icons.mdiMessageAlert }}</v-icon>
            <span>Create Order Issue</span>
          </v-btn>
        </action-confirm-dialog>
        </v-container>

        <order-issue-list :title="false" :OrderId="entity.Id"></order-issue-list>
      </v-tab-item>
      <v-tab-item value="History">

        <v-container class="mt-3 mb-0">
        <action-confirm-dialog @confirmed="CreateOrderLog" dialogTitle="Create Order Log">
          <template v-slot:information>
            <p>Add some information about this order that might need to be referred back to.</p>
          </template>
          <v-btn small color="secondary" class="me-3">
            <v-icon size="18" class="me-1">{{ icons.mdiMessage  }}</v-icon>
            <span>Create Order Log</span>
          </v-btn>
        </action-confirm-dialog>
        </v-container>

        <order-log-list :title="false" :OrderId="entity.Id"></order-log-list>
      </v-tab-item>
      </v-tabs-items>

  </v-card>

  </div>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'
import OrderLogList from '../orderlog/OrderLogList'
import OrderIssueList from '../orderissue/OrderIssueList'
import OrderStatusChip from '@/views/orderstatus/OrderStatusChip'
import OrderSummary from './components/OrderSummary'
import OrderOverviewTab from './components/OrderOverviewTab'
import OrderBillingTab from './components/OrderBillingTab'
import ItemList from '../item/ItemList'
import InvoiceList from '../invoice/InvoiceList'
import { Can } from '@casl/vue'
import ActionConfirmDialog from '@/views/helpers/ActionConfirmDialog'

import {
  mdiPencilOutline,
  mdiPlus,
  mdiViewList,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiClose,
  mdiBookEditOutline,
  mdiPrinter,
  mdiBookOpenVariant,
  mdiCloseCircle,
  mdiMessageAlert,
  mdiMessage
} from '@mdi/js'

import { watch, ref, computed } from '@vue/composition-api'

export default {
  components: {
    Can,
    OrderLogList,
    OrderIssueList,
    OrderStatusChip,
    OrderSummary,
    OrderOverviewTab,
    OrderBillingTab,
    InvoiceList,
    ItemList,
    ActionConfirmDialog
  },
  setup() {

    const { route, router  } = useRouter();

    const entity = computed(() => store.state.app.Order);
    const loading = ref(false);

    const fetchData = (id) => {
      store
        .dispatch('app/fetchOrder', {
          id: id
        })
        .then(response => {
          loading.value = false
        })
        .catch(error => {
          console.log(error);
          router.push({ name: 'order-list'});
        })
    }

    const CanBookAdditionalItems = computed(() => {
      if (!entity.value) return false;

      const allowBookItemStates = ['Active', 'Pending', 'Quote Pending', 'Held', 'Ready'];
      return (allowBookItemStates.includes(entity.value.StatusId_Name));
    });

    const ReloadData = (id) => {

      // reload order
      fetchData(id)

      // reload child lists
      store.dispatch('app/fetchOrderIssues', {
        filter: { OrderId: id },
        options: {
          sortBy: ['Created'],
          sortDesc: [true],
          itemsPerPage: 10
        }
      });
      store.dispatch('app/fetchInvoices', {
        filter: { OrderId: id },
        options: {
          sortBy: ['Created'],
          sortDesc: [true],
          itemsPerPage: 10
        }
      });
      store.dispatch('app/fetchItems', {
        filter: { OrderId: id },
        options: {
          sortBy: ['Created'],
          sortDesc: [true],
          itemsPerPage: 10
        }
      });
      store.dispatch('app/fetchOrderLogs', {
        filter: { OrderId: id },
        options: {
          sortBy: ['Created'],
          sortDesc: [true],
          itemsPerPage: 10
        }
      });
    }
    // fetch the user information when params change
    watch(() => route.value.params.id, (id) => {
      ReloadData(id);
    });

    // and call now
    fetchData(route.value.params.id);


    const booleanIcons = status => {
      if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
      return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
    }

    const currentTab = ref(0)

    const ConvertToQuote = (comment) => {
      store
        .dispatch('app/convertOrderQuote', {
          entity: entity.value,
          comment: comment
        })
        .then(response => {
          loading.value = false;
          router.push({ name: 'order-quote', params: { id: entity.value.Id } });
        })
        .catch(error => {
          console.log(error);
          //router.push({ name: 'order-list'});
        })
    }

    const AcceptQuote = (comment) => {
      store
        .dispatch('app/acceptOrderQuote', {
          entity: entity.value,
          comment: comment
        })
        .then(response => {
          // stay on page
          ReloadData(entity.value.Id);
        })
        .catch(error => {
          console.log(error);

        })
    }

    const CancelOrder = (comment) => {
      store
        .dispatch('app/cancelOrder', {
          id: entity.value.Id,
          entity: entity.value,
          comment: comment
        })
        .then(response => {
          // stay on page
          ReloadData(entity.value.Id);
        })
        .catch(error => {
          console.log(error);
        })
    }

    const CreateAdditionalOrder = () => {
      store
        .dispatch('app/createadditionalOrder', {
          id: entity.value.Id,
          entity: entity.value
        })
        .then(response => {
          // navigate to book-in items for the new order
          router.push({ name: 'order-bookitem', params: { id: entity.value.Id } });
        })
        .catch(error => {
          console.log(error);
        })
    }

    const CreateOrderIssue = (comment) => {
      store
        .dispatch('app/createOrderIssue', {
          order: entity.value,
          title: "User",
          comment: comment
        })
        .then(response => {
          // stay on page
          ReloadData(entity.value.Id);
        })
        .catch(error => {
          console.log(error);

        })
    }

    const CreateOrderLog = (comment) => {
      store
        .dispatch('app/createOrderLog', {
          order: entity.value,
          title: "User",
          comment: comment
        })
        .then(response => {
          // stay on page
          ReloadData(entity.value.Id);
        })
        .catch(error => {
          console.log(error);

        })
    }

    return {
      CreateOrderLog,
      CreateOrderIssue,
      CreateAdditionalOrder,
      CanBookAdditionalItems,
      ReloadData,
      AcceptQuote,
      ConvertToQuote,
      currentTab,
      loading,
      entity,
      Display,
      booleanIcons,
      CancelOrder,
      icons: {
        mdiPencilOutline,
        mdiPlus,
        mdiViewList,
        mdiClose,
        mdiBookEditOutline,
        mdiPrinter,
        mdiBookOpenVariant,
        mdiCloseCircle,
        mdiMessageAlert,
        mdiMessage
      },
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-view::v-deep .v-data-table th:last-child {
  width: 66%;
}
</style>

